.User {
  position: relative;

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .content-container {
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .verify-email {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }

    .addresses-container {

      .addresses {
        display: flex;

        .address-container {
          flex: 1;
        }
      }
    }

    .memberships-container {

      .membership-row {
        margin-bottom: 15px;
      }
    }

    .rewards-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
