.SaveRowModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;

  .modal-body {
    position: absolute;
    background-color: #fff;
    padding: 20px;
    padding-top: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    min-width: 500px;
    max-height: calc(100vh - 5px);
    overflow: auto;

    .close-button {
      height: 26px;
      width: 26px;
      border-radius: 26px;
      text-align: center;
      cursor: pointer;
      right: 5px;
      top: 5px;
      position: absolute;

      i {
        margin-top: 5px;
        color: gray;
        font-size: 40px;
        line-height: 20px;
      }
    }

    .close-button:hover {
      background-color: #eee;
    }

    .title {
      margin: 0;
      margin-bottom: 15px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
    }

    .modal-content {
      margin-bottom: 35px;
      line-height: 22px;

      .input-container {
        display: inline-block;
        width: 100%;

        label {
          font-size: 13px;
          font-weight: bold;
          display: block;
          margin-bottom: 3px;
          margin-top: 10px;
        }

        input {
          font-size: 16px;
          display: block;
          border: 1px solid rgba(0, 0, 0, 0.4);
          padding: 5px;
          border-radius: 4px;
          width: 100%;
        }

        select {
          font-size: 16px;
          display: block;
          border: 1px solid rgba(0, 0, 0, 0.4);
          padding: 5px;
          border-radius: 4px;
          width: 100%;
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 5px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        border: none;
        padding: 7px 10px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 4px;
        cursor: pointer;

        &.danger {
          background-color: #dc3545;
        }

        &.success {
          background-color: #28a745;
        }

        &:hover {
          opacity: 0.8;
        }

        &:disabled {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  @media (max-width: 550px) {
    .modal-body {
      min-width: calc(100% - 30px);
      padding: 10px;
      padding-top: 20px;
    }
  }
}
