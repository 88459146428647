$primaryColor: #e64b38;
$primaryColorLight: #ffb4ab;
$primaryColorMedium: #ffb4ab;
$secondaryHighlight: #0093ad;
$secondaryColor: #ffc600;
$secondaryColorDark: #592e2c;
$secondaryColorLight: #ffd040;
$successColor: #2bb673;
$successColorLight: #aada91;
$successColorDark: #294634;
$alternateColor: #243746;
$alternateColorLight: #a6c4e7;
$tertiaryColor: #3d0f58;
$tertiaryColorLight: #b99ccf;
$borderLight: rgba(0, 0, 0, 0.1);
$borderDark: rgba(0, 0, 0, 0.2);
$transparent: rgba(0, 0, 0, 0);
$linkColor: #0e70be;
$error: #dc3545;
$backgroundColor: #faf7e8;
$textColor: #592e2c;
$lightTextColor: #faf7e8;
