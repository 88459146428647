$primaryColor: #E64B38;
$primaryColorLight: #FFB4AB;
$primaryColorMedium: #FFB4AB;
$secondaryHighlight: #0093AD;
$secondaryColor: #FFC600;
$secondaryColorDark: #592E2C;
$secondaryColorLight: #FFD040;
$successColor: #2BB673;
$successColorLight: #AADA91;
$successColorDark: #294634;
$alternateColor: #243746;
$alternateColorLight: #A6C4E7;
$tertiaryColor: #3D0F58;
$tertiaryColorLight: #B99CCF;
$borderLight: rgba(0, 0, 0, 0.1);
$borderDark: rgba(0, 0, 0, 0.2);
$transparent: rgba(0, 0, 0, 0);
$linkColor: #0e70be;
$error: #dc3545;
$backgroundColor: #FAF7E8;
$textColor: #592E2C;
$lightTextColor: #FAF7E8;
