.Subscription {
  position: relative;

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .content-container {
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .section {
      margin: 20px 0;
    }

    .address-container {
      width: 50%;
      display: inline-block;
    }
  }
}


.subscription-summary {

  .totals-inner {
    margin-top: 5px;
  
    .total-line {
      display: flex;
      justify-content: space-between;
  
      p {
        margin: 0;
        font-size: 0.9rem;
  
        &:first-of-type {
          margin-right: 60px;
          font-weight: 600;
        }
      }
  
      &.grand-total {
        border-top: 1px solid #ddd;
        margin-top: 5px;
        padding-top: 5px;
  
        p {
          font-size: 1.1rem;
        }
      }
    }
  }
}
