.ManagePartner {
  position: relative;

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .content-container {
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .users-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .capitalize {
      text-transform: capitalize;
    }

    .course-modules-products {
      margin-top: 50px;

      .course-modules-products-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }

      .training-course {
        margin-bottom: 20px;

        .training-course-header {
          background-color: rgba(0, 0, 0, 0.05);
          border: 1px solid rgba(0, 0, 0, 0.12);
          padding: 20px;

          p {
            margin: 0;
            font-size: 20px;
          }
        }

        .training-module {
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-top-width: 0;
          padding: 20px;
          padding-left: 40px;

          .training-module-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            p {
              margin: 0;
              font-size: 16px;
            }
          }

          .training-module-product {
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            margin-top: 20px;
            padding: 20px;
            padding-bottom: 0;

            .training-module-product-header {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }

            .training-module-product-input-row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-top: 10px;

              .day-text-field {
                width: calc(50% - 5px);
              }

              .link-url-input {
                flex: 1;
              }

              .MuiFormControlLabel-root {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
