.CreateOrder {
  position: relative;

  .content-container {
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
    
    .totals-container {
      display: flex;
      flex-direction: row-reverse;

      .totals-inner {
        margin-top: 5px;

        .total-line {
          display: flex;
          justify-content: space-between;

          p {
            margin: 0;
            font-size: 0.9rem;

            &:first-of-type {
              margin-right: 60px;
              font-weight: 600;
            }

            &:last-of-type {
              margin-right: 3px;
            }
          }

          &.grand-total {
            border-top: 1px solid #ddd;
            margin-top: 5px;
            padding-top: 5px;

            p {
              font-size: 1.1rem;
            }
          }
        }
      }
    }

    .addresses-container {
      display: flex;

      .address-container {
        flex: 1;
        padding-right: 10px;
      }
    }
  }

  .input-container {

    input {
      border: 1px solid #212121;
      border-radius: 0;
      color: #212121;
      display: inline-block;
      font-family: BuenosAires, sans-serif;
      font-size: 17px;
      line-height: 1;
      max-width: 100%;
      padding: 8px 12px;
      width: 100%;
      border-radius: 4px;
      border-color: #bfbfbf;
      color: #404040;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #aaa;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #aaa;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #aaa;
      }
    }
  }
}
