.ReorderProducts{
  position: relative;

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .content-container {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
  }
}
