.RewardsDashboard {
  position: relative;

  .content {
    background-color: #fff;
    padding: 20px 15px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .sub-title-container {
      margin: 20px -16px;
      display: flex;
      justify-content: space-between;
      background-color: lightgray;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }

    .dates-row {
      display: flex;
      position: relative;
      z-index: 1000;
      margin-bottom: 30;
      align-content: flex-end;

      .dates-row-item-container {
        display: flex;
        flex-direction: column-reverse;
        margin-left: 10px;

        button {
          margin-bottom: 3px;
        }

        .earning-type-select {
          width: 150px;
          margin-bottom: 3px;

          .MuiOutlinedInput-inputMarginDense {
            padding-bottom: 9px;
            padding-top: 9px;
          }
        }
      }
    }
  }
}
