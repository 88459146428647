html,
body {
  padding: 0;
  margin: 0;
  font-family: BuenosAires, sans-serif;
  line-height: 1.6;
  font-size: 16px;
  overflow-x: hidden;
}

body {
  font-family: BuenosAires, sans-serif;
}

table {
	width: 100%;
}

* {
  box-sizing: border-box;
}

figure,
ol,
ul {
	margin: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
}

@font-face {
	font-family: BuenosAires;
	src: url(https://pupford-fonts.b-cdn.net/BuenosAiresVariableUpright.ttf);
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-webfont.woff) format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-oblique-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-oblique-webfont.woff) format("woff");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-webfont.woff) format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-oblique-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-oblique-webfont.woff) format("woff");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-webfont.woff) format("woff");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-oblique-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-oblique-webfont.woff) format("woff");
	font-weight: 800;
	font-style: italic
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  max-width: 61rem;
  padding: 0 1rem;
  margin: 0 auto;
}

button,
.button {
  background-color: #ff3312;
  color: #fff;
  border-radius: 4px;
  padding: 0;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

button.secondary,
.button.secondary {
  background-color: #e7ac00;
}

button.success,
.button.success {
  background-color: #2BB673;
}

button.small,
.button.small {
  font-size: 13px;
  padding: 10px 15px;
}

button:hover,
.button:hover {
  background-color: #000;
}

button.success:hover,
.button.success:hover {
  background-color: #2BB673;
  opacity: 0.8;
}

button:focus,
.button:focus {
  outline:0;
}

button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

label {
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
  font-weight: 600;
}

.page-builder-medium-visible {
  display: none !important;
}

.page-builder-medium-small-visible {
  display: none !important;
}

.page-builder-small-visible {
  display: none !important;
}

@media (max-width: 1200px) {
  .page-builder-largest-stack {
    flex-direction: column;

    &.reverse-when-stacked {
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: 992px) {
  .page-builder-large-stack {
    flex-direction: column;

    &.reverse-when-stacked {
      flex-direction: column-reverse;
    }
  }

  .page-builder-large-visible {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .page-builder-medium-stack {
    flex-direction: column;

    &.reverse-when-stacked {
      flex-direction: column-reverse;
    }
  }

  .page-builder-medium-visible {
    display: inherit !important;
  }

  .page-builder-medium-small-visible {
    display: inherit !important;
  }
}

@media (max-width: 567px) {
  .page-builder-small-stack {
    flex-direction: column;

    &.reverse-when-stacked {
      flex-direction: column-reverse;
    }
  }

  .page-builder-large-medium-visible {
    display: none !important;
  }

  .page-builder-medium-visible {
    display: none !important;
  }

  .page-builder-small-visible {
    display: inherit !important;
  }
}
