.StatisticItemContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  width: 100%;
  max-width: 90%;

  .StatisticItemImage {
    height: 80px;
    width: 80px;
    object-fit: cover;
  }

  .StatisticItemQuantity {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
    text-wrap: nowrap;
    font-family: BuenosAires, sans-serif;
  }

  .StatisticItemTitle {
    font-size: 16px;
    margin: 0;
    text-wrap: wrap;
  }

  .StatisticItemStarsQuantity {
    margin: 0;
  }

  .StatisticContentContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

@media (min-width: 550px) {
  .StatisticItemContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 250px;

    .StatisticItemImage {
      height: 160px;
      width: 160px;
      object-fit: cover;
    }
  }
}
