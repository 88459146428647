.FormMultiSelect {
  position: relative;

  .options-container {
    margin: 7px 0;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);

    .option-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        color: rgba(0, 0, 0, 0.54);
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
      }

      .option-delete-button {
        margin-left: 7px;
      }
    }
  }
}
