.OrderBumpPerformance {
  position: relative;
  padding: 20px;
  min-height: 350px;

  .date-picker-container {
    position: relative;
    z-index: 100;
  }

  .card-row {
    margin: 5px -.5%;

    .card-container {
      width: calc(49%);
      margin-left: .5%;
      margin-right: .5%;
      display: inline-block;

      .MuiCard-root {
        height: 100%;
      }

      .card-spacing {
        margin-bottom: 5px;
      }
    }
  }
}
