.EditProduct {

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .product-name-field {
    width: 49%;
  }

  a {
    text-decoration: none;
  }

  .content-container {
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
  }

  .memberships-container {
    margin-bottom: 10px;
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
    margin-top: 0;
  }

  .toggle-row {
    margin-bottom: 10px;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .row-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .path-container {
    width: 49%;
    .MuiInputAdornment-positionStart {
      margin-right: 0;
    }
  }

  .media-container {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 10px;
    border-radius: 4px;
    margin: 20px 0;
    width: 100%;

    .media-label {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 0.75rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      background-color: #fff;
      margin-top: -20px;
      padding: 5px;
      width: fit-content;
    }

    .images-container {
      display: flex;
      margin-top: 20px;

      .image-heading {
        font-size: small;
        color: rgba(0, 0, 0, 0.6);
        margin-left: 5px;
        margin-top: 5px;
      }

      .main-image-container {
        width: 50%;

        img {
          width: 100%;
        }
      }

      .reorder-container {
        width: 50%;

        .file-container {

          .file-container-inner {
            display: flex;
            width: 100%;
            background-color: #fff;

            &:hover {
              background-color: rgba(0, 0, 0, 0.04);
            }

            .file-image {
              flex: 1;

              .image {
                width: 100%;
                padding-top: 100%;
                position: relative;

                img {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }

            .file-details {
              flex: 2;
              position: relative;
              padding-left: 10px;

              .file-title {
                font-size: 16px;
                font-weight: bold;
              }

              .edit-button-container {
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }
        }
      }
    }
  }

  .add-category-button {
    color: blue;
    cursor: pointer;
  }

  .categories-container {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 15px;
    border-radius: 4px;
    margin: 20px 0;
    width: 100%;

    .MuiInputLabel-shrink {
      background-color: #fff;
      padding: 0 5px;
    }
  }

  .section-container {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 15px;
    border-radius: 4px;
    margin: 20px 0;
    width: 100%;

    .section-label {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 0.75rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      background-color: #fff;
      margin-top: -25px;
      padding: 5px;
      width: fit-content;
    }

    .pricing-row {
      margin-top: 20px;
    }

    .right-container {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 15px;
    }

    .description-section {
      position: relative;

      .description-actions {
        display: flex;
        position: absolute;
        right: 45px;
        z-index: 1;
      }
    }
  }

  .add-to-subscription-row {
    padding: 20px;

    .add-to-subscription-label {
      margin-right: 10px;
    }
  }

  .product-feature-row {
    display: flex;
    flex-direction: row;

    .delete-feature-container {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  .product-faq-divider {
    margin: 10px 0 6px;
  }

  .product-faq-row {
    display: flex;
    flex-direction: row;

    .product-faq-inputs-container {
      flex: 1;
    }

    .delete-faq-container {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
}
