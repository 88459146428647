.CustomerLTV {
  position: relative;

  .date-picker-row {
    display: flex;
    position: relative;
    z-index: 1000;
    margin-bottom: 30px;
    align-items: flex-end;

    .date-picker-submit-button {
      margin-bottom: 1px;
      margin-left: 7px;
    }
  }

  .card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -5px;
    align-items: stretch;
    margin-top: 10px;

    .card-container {
      margin: 5px;
      flex: 1;

      .MuiCard-root {
        height: 100%;
      }

      .card-spacing {
        margin-bottom: 5px;
      }
    }
  }

  .monthly-statistics-header {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .monthly-totals-header {
    margin-top: 50px;
  }

  .monthly-totals-buttons {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
