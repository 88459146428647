.GetStartedContainer {
  background-repeat: no-repeat;
  position: relative;
  min-height: 500px;
  display: flex;
  background-size: cover;
  background-position: center;
  text-align: center;
  font-family: BuenosAires, sans-serif;

  .overlay {
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .content {
    position: inherit;
    margin: auto;
    height: 100%;
    width: 80%;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding: 56px 16px;
  }

  .title {
    font-size: 40px;
    color: white;
    margin: 0;
  }

  .button {
    color: white;
    border: 2px solid white;
    border-radius: 25px;
    background-color: transparent;
    text-transform: uppercase;
  }

  .word {
    font-size: 16px;
  }
}
