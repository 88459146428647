.Shipping {

  .tabs-container {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .container {
      padding: 20px;
    }
  }
}
