.FeaturedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-family: BuenosAires, sans-serif;

  .title {
    font-size: 20px;
    color: black;
    text-align: center;
  }

  .logosContainer {
    display: flex;
    flex-direction: row;
    gap: 56px;
    padding-bottom: 16px;
    overflow-x: scroll;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .logo {
    width: 160px;
    height: 50px;
    object-fit: contain;
  }
}

@media (min-width: 768px) {
  .FeaturedContainer {
    .logosContainer {
      max-width: 70%;
    }
  }
}
