.EditHeaderBanner {
  position: relative;

  .banner-preview {
    font-family: BuenosAires, sans-serif;

    .banner-scroll {
      display: flex;
      position: relative;
      height: 30px;
      margin: auto;
      overflow: hidden;
      z-index: 1;
    }

    .banner-scroll-title {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      // transition: all 1s ease;
    }

    .banner-title {
      font-family: BuenosAires, sans-serif;
    }

    .banner-scroll-title>div {
      display: flex;
      animation: scrollText 60s infinite linear;
    }

    .banner-scroll-title .marquee-text-container {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      // transition: all 2s ease;
    }

    .banner-scroll-title>div:hover {
      animation-play-state: paused;
    }

    @keyframes scrollText {
      from {
        transform: translateX(0%);
      }

      to {
        transform: translateX(-50%);
      }
    }

    .marquee-text {
      margin-right: 150px;
    }
  }

  .container {
    padding: 20px;
    padding-bottom: 80px;
    width: 100%;

    .banner-settings-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .banner-setting-container {
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        padding: 10.5px 14px;
        position: relative;
        width: 210px;
        margin-right: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        label {
          position: absolute;
          color: rgba(0, 0, 0, 0.54);
          font-size: 12px;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: 400;
          line-height: 1;
          top: -6px;
          left: 11.5px;
          padding: 0 3px;
          background: #fff;
        }

        p {
          font-size: 16px;
          margin: 0;
        }

        .banner-setting-buttons-container {
          display: flex;
          flex-direction: row;
          margin-right: -14px;

          .banner-setting-button {
            background-color: rgba(0, 0, 0, 0.03);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            margin: -22px 0;
            width: 40px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-size: 20px;
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.increase {
              border-radius: 0 4px 4px 0;
            }

            &:hover {
              background-color: rgba(0, 0, 0, 0.01);
            }
          }
        }
      }

      .color-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        display: inline-flex;
        box-sizing: border-box;
        align-items: center;
        margin-top: 23px;
        margin-bottom: 4px;
        cursor: pointer;
        padding: 11.8px 14px;
        margin-right: 15px;
        min-width: 210px;

        &.last {
          margin-right: 0;
        }

        p {
          color: rgba(0, 0, 0, 0.87);
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.1876em;
          letter-spacing: 0.00938em;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          margin: 0;
        }

        .color-preview {
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          height: 20px;
          width: 20px;
        }
      }

      @media (max-width: 880px) {
        flex-direction: column;
        align-items: flex-start;

        .banner-setting-container {
          margin-top: 20px;
        }
      }
    }

    .section-container {
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 15px;
      border-radius: 4px;
      margin: 20px 0;
      width: 100%;

      .section-label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 0.75rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
        background-color: #fff;
        margin-top: -25px;
        padding: 5px;
        width: fit-content;
      }

      .right-container {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 15px;
      }

      .pages-header-container {
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        justify-content: space-between;
      }

      .banner-item-divider {
        margin: 10px 0 6px;
      }

      .banner-item-row {
        display: flex;
        flex-direction: row;

        .banner-item-inputs-container {
          flex: 1;
          margin-right: 10px;
        }

        .delete-banner-item-container {
          margin-left: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }
    }
  }
}