.Form {
  position: relative;

  .last-modified-container {
    color: rgba(0, 0, 0, 0.5);
    font-size: 13px;
    margin-bottom: 5px;
  }

  .content-container {
    padding: 10px;
    padding-bottom: 0;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .preview {
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
    }

    .tabs-container {
      margin: 10px -10px 0;

      .pages-container {
        display: flex;
        margin: 10px 0;

        .left-container {
          flex: 1;
          padding: 10px;

          .form-page-container {
            position: relative;

            .action-buttons-container {
              position: absolute;
              top: -5px;
              right: -5px;
              display: flex;
              flex-direction: row;

              .action-button {
                cursor: pointer;
                height: 20px;
                width: 20px;
                border-radius: 20px;
                text-align: center;
                background-color: #ccc;
                z-index: 1;
                margin-left: 3px;

                &:hover {
                  background-color: #bbb;
                }

                .MuiIconButton-sizeSmall {
                  padding: 0px;
                  font-size: 1rem;
                  margin-top: -2px;
                }
              }
            }

            .delete-button {
              position: absolute;
              top: -5px;
              right: -5px;
              cursor: pointer;
              height: 20px;
              width: 20px;
              border-radius: 20px;
              text-align: center;
              background-color: #ccc;
              z-index: 1;

              &:hover {
                background-color: #bbb;
              }

              p {
                line-height: 19px;
                margin: 0;
                height: 0;
              }
            }
          }

          .page-flow-container {
            width: 100%;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            padding: 0 10px;
            text-align: center;
            margin-bottom: 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);

            &.form-page {
              cursor: pointer;
              border: 1px solid rgba(0, 0, 0, 0.3);

              &.selected {
                border-color: #ff3312;
              }

              &:hover {
                opacity: 0.85;
              }
            }
          }
        }

        .right-container {
          flex: 2;
          margin: 10px;
          padding: 15px;
          border: 1px solid #ddd;
          border-radius: 4px;

          .page-input-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .MuiFormControl-root,
            .color-container {

              &:first-of-type {
                margin-right: 5px;
              }

              &:last-of-type {
                margin-left: 5px;
              }
            }

            .color-container {
              flex: 1;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              border: 1px solid rgba(0, 0, 0, 0.23);
              border-radius: 4px;
              display: inline-flex;
              box-sizing: border-box;
              align-items: center;
              margin-top: 8px;
              margin-bottom: 4px;
              cursor: pointer;
              padding: 10.5px 14px;

              p {
                color: rgba(0, 0, 0, 0.87);
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.1876em;
                letter-spacing: 0.00938em;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                margin: 0;
              }

              .color-preview {
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                height: 20px;
                width: 20px;
              }
            }
          }

          .form-items-heading {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 10px -16px;
            border: 1px solid #ddd;
            background-color: rgba(0, 0, 0, 0.05);
          }

          .form-item-container {
            margin-bottom: 10px;
            background-color: rgba(0, 0, 0, 0.01);
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            padding: 10px;

            .form-item-header {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-end;

              .form-item-type {
                font-weight: bold;
                text-transform: capitalize;
                margin-bottom: 7px;
              }

              .form-item-actions-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              }
            }

            .form-input-row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .MuiFormControl-root {

                &:first-of-type {
                  margin-right: 5px;
                }
  
                &:last-of-type {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

      @media (max-width: 1000px) {
        .pages-container {
          flex-direction: column;
        }
      }
    }
  }
}
