.WholesaleProduct {
  position: relative;

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .content-container {
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .pricing-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
