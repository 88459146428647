.EditShippingZone {

  .edit-content-container {
    padding: 20px;

    .table-container {
      margin: 30px 0;

      &.last {
        margin-bottom: 100px;
      }
    }
  }
}
