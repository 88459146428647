.SubscriptionForecastByCategory {
  position: relative;

  .date-pickers-container {
    display: flex;
    position: relative;
    z-index: 1000;
    margin: 30px 0;

    .date-picker-container {
      position: relative;
      z-index: 100;
    }
  }
}
