.Reviews {
  position: relative;

  .import-button-container {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row-reverse;
  }

  .pagination-container {
    padding: 20px;
    display: flex;
    flex-direction: row-reverse;

    .page-text {
      margin: 0 10px;
    }
  }
}
