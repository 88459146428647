.FormActions {
  position: relative;

  .capitalize {
    text-transform: capitalize;
  }
}

.add-form-action-modal {

  .add-form-action-modal-item {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 7px;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.3);

    &.selected {
      border-color: #ff3312;
    }

    &:hover {
      opacity: 0.85;
    }

    p {
      margin: 0;
    }

    .add-form-action-modal-item-title {
      font-size: 14px;
      font-weight: 600;
    }

    .add-form-action-modal-item-text {
      font-size: 12px;
    }
  }
}
