.Wholesale {
  position: relative;

  .content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .text-spacing {
      margin-top: 10px;
    }

    .date-picker-row {
      display: flex;
      position: relative;
      z-index: 1000;
      margin-bottom: 30px;
      align-items: flex-end;

      .date-picker-submit-button {
        margin-bottom: 1px;
        margin-left: 7px;
      }
    }
  }
}
