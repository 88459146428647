.Orders {
  position: relative;

  .pagination-container {
    padding: 20px;
    display: flex;
    flex-direction: row-reverse;

    .page-text {
      margin: 0 10px;
    }
  }
}
