.SubscriptionRetention {
  position: relative;
  padding-bottom: 150px;

  .content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .card-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: -5px;
      align-items: stretch;
      margin-bottom: 20px;
  
      .card-container {
        margin: 5px;
        flex: 1;
  
        .MuiCard-root {
          height: 100%;
        }
  
        .card-spacing {
          margin-bottom: 5px;
        }
      }
    }
  
    @media (max-width: 800px) {
      .card-row {
        flex-direction: column;
      }
    }

    .date-picker-container {
      position: relative;
      z-index: 100;
    }

    .date-submit-button-container {
      display: flex;
      flex-direction: column-reverse;
      margin-left: 10px;
      padding-bottom: 3px;
    }
  }
}
