.ProductTagPage {

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .content-container {
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .form-field-container {
      margin-top: 10px;
    }

    .products-container {
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 15px;
      border-radius: 4px;
      margin-top: 10px;
      width: 100%;
  
      .MuiInputLabel-shrink {
        background-color: #fff;
        padding: 0 5px;
      }
    }

    .section-container {
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 15px;
      border-radius: 4px;
      margin: 20px 0;
      width: 100%;

      .section-label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 0.75rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
        background-color: #fff;
        margin-top: -25px;
        padding: 5px;
        width: fit-content;
      }

      .section-header {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 10px;
      }

      .additional-section {
        margin-bottom: 10px;

        .additional-section-row {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .additional-section-inputs-container {
            flex: 1;
          }

          .additional-section-buttons-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
