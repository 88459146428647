.FormActionEditor {
  position: relative;

  .form-action-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  .form-action-description {
    margin: 0;
    margin-bottom: 10px;
  }

  .additional-field-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .day-text-field {
      margin-right: 10px;
    }
  }
}
