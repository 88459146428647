.RetailMicrositeSettings {
  position: relative;

  .content {
    background-color: #fff;
    padding: 20px 15px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    table {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-top: none;
    }

    .row {
      display: flex;
      flex-direction: row;
    }

    .row > * {
      flex: 1;
      margin: 10px 5px;
    }

    .sub-title-container {
      margin: 20px -16px;
    }

    .course-image-container {
      background-color: #fff;
      width: 75px;
      height: 75px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      overflow: hidden;

      img {
        width: 100%;
        display: block;
      }
    }
  }
}
