.SubscriptionForecast {
  position: relative;

  .card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -5px;
    align-items: stretch;

    .card-container {
      margin: 5px;
      flex: 1;

      .MuiCard-root {
        height: 100%;
      }

      .card-spacing {
        margin-bottom: 5px;
      }
    }
  }

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .sub-title-container {
      margin: 40px -21px 20px;
    }
  }
}
