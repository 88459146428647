.UserDashboard {
  position: relative;
  padding-bottom: 100px;

  .content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .date-picker-container {
      position: relative;
      z-index: 100;
    }

    .date-submit-button-container {
      display: flex;
      flex-direction: column-reverse;
      margin-left: 10px;
      padding-bottom: 3px;
    }
  }

  .section-container {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 15px;
    border-radius: 4px;
    margin: 20px 0;
    width: 100%;

    .section-label {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      background-color: #fff;
      margin-top: -25px;
      padding: 5px;
      width: fit-content;
      margin-bottom: 10px;
    }
  }

  .card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -5px;
    align-items: stretch;

    .card-container {
      margin: 5px;
      flex: 1;

      .MuiCard-root {
        height: 100%;
      }

      .card-spacing {
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 800px) {
    .card-row {
      flex-direction: column;
    }
  }
}
