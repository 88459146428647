.ShippingZones {
  
  .add-container {
    display: flex;
    flex-direction: row-reverse;
  }

  .list-value {
    margin-right: 20px;
  }
}
