.BlogComments {
  position: relative;
}

.blog-post-comments-modal-container {

  .modal-container {
    padding: 20px;
  }
}
