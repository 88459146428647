.EditModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 100000;
  cursor: not-allowed;
  text-align: left;

  .modal-body {
    position: absolute;
    background-color: #fff;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px 4px 0 0;
    min-width: 900px;
    height: 300px;
    max-height: calc(100vh - 5px);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-bottom: 0;
    cursor: initial;

    .modal-inner {
      height: 100%;

      .heading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 20px;
        padding-bottom: 0;
        padding-top: 30px;
        background-color: #fff;
        border-radius: 35px;

        .close-button {
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          border: none;
          padding: 7px 10px;
          background-color: rgba(0, 0, 0, 0.7);
          border-radius: 4px;
          cursor: pointer;
          right: 5px;
          top: 5px;
          position: absolute;
  
          &:hover {
            opacity: 0.8;
          }
        }
  
        .title {
          margin: 0;
          margin-bottom: 10px;
          border-bottom: 1px solid #ddd;
          padding-bottom: 10px;
        }
      }

      .modal-content {
        padding: 10px 20px 50px;
        margin-top: 63px;
        height: calc(100% - 63px);
        overflow: scroll;
        position: relative;

        .input-container {
          display: inline-block;
          margin: 5px;

          label {
            font-size: 13px;
            font-weight: bold;
            display: block;
            margin-bottom: 3px;
          }

          input {
            font-size: 16px;
            display: block;
            border: 1px solid rgba(0, 0, 0, 0.4);
            padding: 5px;
            border-radius: 4px;
          }

          select {
            font-size: 16px;
            display: block;
            border: 1px solid rgba(0, 0, 0, 0.4);
            padding: 5px;
            border-radius: 4px;
            width: 178px;
          }
        }
      }

      .buttons-container {
        display: flex;
        justify-content: flex-end;

        button {
          margin-left: 5px;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          border: none;
          padding: 7px 10px;
          background-color: rgba(0, 0, 0, 0.7);
          border-radius: 4px;
          cursor: pointer;

          &.danger {
            background-color: #dc3545;
          }

          &.success {
            background-color: #28a745;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  @media (max-width: 950px) {
    .modal-body {
      min-width: calc(100% - 30px);
      padding: 10px;
      padding-top: 20px;
    }
  }
}
