.WebsiteDashboard {
  position: relative;

  .content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
  }

  .section-container {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 15px;
    border-radius: 4px;
    margin: 20px 0;
    width: 100%;

    .section-label {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      background-color: #fff;
      margin-top: -25px;
      padding: 5px;
      width: fit-content;
      margin-bottom: 10px;
    }

    .categories-container {
      margin-top: 20px;
    }

    small {
      color:blue;
      cursor: pointer;
    }

    .contact-container {
      margin-bottom: 20px;

      .MuiTypography-subtitle1 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        button {
          margin-left: 5px;
        }
      }
    }
  }
}
