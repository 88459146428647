.section-container {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 15px;
  border-radius: 4px;
  margin: 20px 0;
  width: 100%;

  .section-label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    background-color: #f5f5f5;
    margin-top: -25px;
    padding: 5px;
    width: fit-content;
  }
}
