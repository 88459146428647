.EditGroup {

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
  }

  .content-container {
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .form-row {
      margin-top: 10px;
    }

    .add-topic-button {
      color: blue;
      cursor: pointer;
    }

    .topics-container {
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 15px;
      border-radius: 4px;
      margin: 20px 0;
      width: 100%;

      .MuiInputLabel-shrink {
        background-color: #fff;
        padding: 0 5px;
      }
    }
  }
}
