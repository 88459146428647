.AccountCredits {

  .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .content-container {
    padding: 20px;
  }
}
