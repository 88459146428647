.Products {
  position: relative;

  .top-buttons-container {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
  }

  small {
    color: rgba(0, 0, 0, 0.5);
    font-size: 11px;
  }

  .stock-status {
    font-weight: bold;
    font-size: 12px;
  }
}
