.GroupPosts {

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .content {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .posts-container {
      width: 100%;
      max-width: 600px;

      .post-container {
        margin: 10px 0;

        .post-profile-picture {
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }

        .video-container {
          position: relative;
          width: 100%;
          padding-top: 56.25%;

          iframe {
            border: none;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
          }
        }

        .post-status {
          text-transform: capitalize;
        }
      }

      .pagination-container {
        padding: 20px;
        display: flex;
        flex-direction: row-reverse;

        .page-text {
          margin: 0 10px;
        }
      }
    }
  }
}
