.ShippingInsurance {

  .content-container {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
    padding: 20px;
    background-color: #fff;

    @media (min-width: 900px) {
      .input-row {
        display: flex;
        flex-direction: row;
  
        .day-text-field:first-child {
          margin-right: 5px;
        }
  
        .day-text-field:last-child {
          margin-left: 5px;
        }
      }
    }

    .price-tier-row {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid rgba(0, 0, 0, 0.23);
      padding-top: 10px;
      padding-bottom: 10px;

      &:last-of-type {
        border-bottom: none;
      }

      .input-row {
        flex: 1;
      }

      .delete-price-tier-icon {
        margin-left: 10px;
      }
    }

    .price-tiers-add-button-container {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 10px;
    }

    .section-container {
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 15px;
      border-radius: 4px;
      margin: 20px 0;
      width: 100%;

      .section-label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
        background-color: #fff;
        margin-top: -25px;
        padding: 5px;
        width: fit-content;
        margin-bottom: 10px;
      }

      .rules-button-row {
        display: flex;
        flex-direction: row-reverse;
      }

      .condition-text {
        font-weight: bold;
      }

      .rule-group {
        border: 1px solid #ddd;
        margin: 20px 0;
        padding: 10px;

        .form-row {
          display: flex;
          flex-direction: row;
          margin: 20px 0;
          padding: 20px 0;
        }
      }
    }
  }
}
