@import '../../../../../../styles/colors';

.AcademyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: $textColor;
  padding: 56px 16px;
  font-family: BuenosAires, sans-serif;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .gridItem {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: left;
    align-items: flex-start;
    padding: 16px 0px;
  }

  .AcademyTitle {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }

  .AcademySubtitle {
    font-size: 32px;
    margin: 0;
  }

  .categoryText {
    margin: 0;
  }

  .categoryTitle {
    font-weight: 600;
  }

  .AcademyContent {
    margin: 0;
    font-size: 18px;
  }

  .image {
    max-height: 400px;
    width: 100%;
    object-fit: contain;
  }

  .AcademyButton {
    background: transparent;
    text-transform: uppercase;
    font-weight: 400;
    font-family: BuenosAires, sans-serif;
    text-wrap: nowrap;
    border-radius: 25px;
    align-self: center;
    margin-top: 8px;
  }

  .imageContainer {
    display: flex;
    justify-content: center;
    max-height: 600px;
  }

  @media (max-width: 768px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }

  @media (min-width: 768px) {
    .grid {
      max-width: 90%;
    }
    .contentContainer {
      padding: 0px 16px;
    }
  }
}
