.GroupPost {

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .content {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .post-container {
      width: 100%;
      max-width: 600px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-top-width: 0;
      border-bottom-width: 0;

      .card {
        box-shadow: none;

        .post-profile-picture {
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }

        .video-container {
          position: relative;
          width: 100%;
          padding-top: 56.25%;

          iframe {
            border: none;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
          }
        }

        .comments-container {
          padding: 0 10px;

          .comment {
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            padding-top: 10px;

            .comment-top-container {
              display: flex;
              flex-direction: row;

              .comment-image-container {
                margin-right: 10px;
              }

              .comment-top-details {
                flex: 1;

                .comment-user-name {
                  margin: 0;
                  font-size: 12px;
                }

                .comment-created-date {
                  margin: 0;
                  font-size: 11px;
                }
              }
            }

            .comment-text {
              font-size: 13px;
            }

            .actions-row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;

              .reply-button-text {
                font-size: 12px;
                cursor: pointer;
                transition: all 200ms ease-in-out;
                color: gray;
              }

              .likes-text {
                font-size: 12px;
                color: gray;
              }
            }
          }
        }
      }
    }
  }
}
