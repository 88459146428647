.drag-card {
  width: calc(100% - 40px);
}

.day-text-field {
  width: 100%;
}

.main-container {
  margin-top: 40px;
}

@media (min-width: 600px) {
  .main-container {
    width: calc(100% - 168px);
    margin-left: 168px;
  }
}

.order-cards-container {
  display: flex;
}

.order-cards {
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
}

@media (max-width: 1000px) {
  .order-cards-container {
    flex-direction: column;
  }

  .order-cards {
    margin-bottom: 10px;
  }
}

@media (max-width: 500px) {
  .orders-card {
    padding: 0 !important;
  }
}

.react-datepicker-wrapper input {
  padding: 10px;
  font-size: 13px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 150px;
}

.date-divider {
  font-size: 35px;
    vertical-align: center;
    align-self: center;
    margin: -8px 4px 0;
}

.navigation .MuiTypography-body1 {
  font-size: 0.9rem;
  line-height: 1.1;
}

.notification-count-container {
  background-color: #ff3312;
  color: #fff;
  display: inline-block;
  height: 16px;
  min-width: 16px;
  border-radius: 50%;
  line-height: 17px;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}

button.MuiTab-root:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.react-calendar__month-view__weekdays__weekday {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none;
}

.react-calendar__tile, .react-calendar__navigation__label, .react-calendar__navigation__arrow {
  background-color: #fff;
  color: #000;
  font-weight: 400;
}

.react-calendar__tile:hover {
  background-color: #eee;
}

.react-calendar__navigation__label:hover {
  background-color: #eee;
}

.react-calendar__navigation__arrow:hover {
  background-color: #eee;
}

.react-calendar__tile.react-calendar__tile--active {
  background-color: #016EDD;
  color: #fff;
}
