@import "colors";

.HomePagePreview {

  @font-face {
    font-family: BuenosAires;
    src: url(https://pupford-fonts.b-cdn.net/BuenosAiresVariableUpright.ttf);
  }

  html,
  body {
    padding: 0;
    margin: 0;
    line-height: 1.6;
    font-size: 16px;
    overflow-x: hidden;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01em;
  }

  p {
    font-size: 16px;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    font-family: BuenosAires, sans-serif;
  }

  body.modal-open {
    overflow: hidden;
    position: fixed;
  }

  table {
    width: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  figure,
  ol,
  ul {
    margin: 0
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .container {
    max-width: 61rem;
    padding: 0 1rem;
    margin: 0 auto;
  }

  button,
  .button {
    background-color: $primaryColor;
    color: #FAF7E8;
    border-radius: 25px;
    padding: 0;
    border: none;
    padding: 12px 24px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;

    &.secondary {
      background-color: $secondaryColorDark;
    }

    &.success {
      background-color: $successColor;
    }

    &.alternate {
      background-color: $alternateColor;
    }

    &.small {
      font-size: 14px;
      padding: 6px 12px;
    }
  }

  button:hover,
  .button:hover {
    opacity: 0.8;
  }

  button.alternate:hover,
  .button.alternate:hover {
    background-color: $alternateColor;
    opacity: 0.8;
  }

  button.success:hover,
  .button.success:hover {
    background-color: $successColor;
    opacity: 0.8;
  }

  button:focus,
  .button:focus {
    outline: 0;
  }

  button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }

  label {
    font-size: 14px;
    margin-bottom: 4px;
    display: block;
    font-weight: 600;
  }

  select {
    background: linear-gradient(45deg, transparent 50%, $textColor 50%), linear-gradient(135deg, $textColor 50%, transparent 50%), linear-gradient(to right, $transparent, $transparent);
    background-position: calc(100% - 21px) calc(1em + 5px), calc(100% - 16px) calc(1em + 5px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    line-height: 1.4;
    border-radius: 8px;
    position: relative;
    color: $textColor;
    box-shadow: none;
    border: 2px solid $secondaryColorDark;
    background-color: $transparent;
    padding: 8px 12px;
    padding-right: 35px;
    width: 100%;
    font-size: 16px;
    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1.65;

    &.error {
      border-color: #dc3545;
    }
  }

  select:focus {
    outline: none;
  }

  textarea,
  input[type=email],
  input[type=text],
  input[type=number],
  input[type=password],
  input[type=date] {
    border: 2px solid $secondaryColorDark;
    background-color: $transparent;
    border-radius: 8px;
    position: relative;
    color: $textColor;
    box-shadow: none;
    padding: 10px 12px;
    font-size: 16px;
    width: 100%;
    line-height: 1;
    max-width: 100%;
    font-family: BuenosAires, sans-serif;

    &.error {
      border-color: $primaryColor;
    }
  }

  input[type=email]:focus,
  input[type=text]:focus,
  input[type=number]:focus,
  input[type=password]:focus {
    outline-width: 0;
    border-color: #61bdf7;
  }

  .well {
    background: #FFFFFF;
    border: 1px solid $borderDark;
    border-radius: 6px;
    padding: 20px 25px;

    &.well-secondary {
      border-color: rgba(255, 51, 18, 0.2);
    }

    &.well-light {
      border-color: $borderLight;
    }

    &.well-large {
      border-radius: 20px;
    }
  }

  .divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    &.divider-light {
      border-bottom: 1px solid $borderDark;
    }
  }

  .page-builder-medium-visible {
    display: none !important;
  }

  .page-builder-medium-small-visible {
    display: none !important;
  }

  .page-builder-small-visible {
    display: none !important;
  }

  @media (max-width: 1200px) {
    .page-builder-largest-stack {
      flex-direction: column;

      &.reverse-when-stacked {
        flex-direction: column-reverse;
      }
    }
  }

  @media (max-width: 992px) {
    .page-builder-large-stack {
      flex-direction: column;

      &.reverse-when-stacked {
        flex-direction: column-reverse;
      }
    }

    .page-builder-large-visible {
      display: none !important;
    }
  }

  @media (max-width: 768px) {
    .page-builder-medium-stack {
      flex-direction: column;

      &.reverse-when-stacked {
        flex-direction: column-reverse;
      }
    }

    .page-builder-large-medium-visible {
      display: none !important;
    }

    .page-builder-medium-visible {
      display: inherit !important;
    }

    .page-builder-medium-small-visible {
      display: inherit !important;
    }
  }

  @media (max-width: 567px) {
    .page-builder-small-stack {
      flex-direction: column;

      &.reverse-when-stacked {
        flex-direction: column-reverse;
      }
    }

    .page-builder-medium-visible {
      display: none !important;
    }

    .page-builder-small-visible {
      display: inherit !important;
    }
  }

  .desktop-600,
  .desktop-650,
  .desktop-700,
  .desktop-750,
  .desktop-800,
  .desktop-850,
  .desktop-900,
  .desktop-950,
  .desktop-1000,
  .desktop-1050,
  .desktop-1100,
  .desktop-1150,
  .desktop-1200,
  .mobile-600,
  .mobile-650,
  .mobile-700,
  .mobile-750,
  .mobile-800,
  .mobile-850,
  .mobile-900,
  .mobile-950,
  .mobile-1000,
  .mobile-1050,
  .mobile-1100,
  .mobile-1150,
  .mobile-1200 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-600,
  .mobile-650,
  .mobile-700,
  .mobile-750,
  .mobile-800,
  .mobile-850,
  .mobile-900,
  .mobile-950,
  .mobile-1000,
  .mobile-1050,
  .mobile-1100,
  .mobile-1150,
  .mobile-1200 {
    display: none;
  }

  .instagram-media {
    width: 100%;
  }

  @media (max-width: 1200px) {
    .desktop-1200 {
      display: none;
    }

    .mobile-1200 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 1150px) {
    .desktop-1150 {
      display: none;
    }

    .mobile-1150 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 1100px) {
    .desktop-1100 {
      display: none;
    }

    .mobile-1100 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 1050px) {
    .desktop-1050 {
      display: none;
    }

    .mobile-1050 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 1000px) {
    .desktop-1000 {
      display: none;
    }

    .mobile-1000 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 950px) {
    .desktop-950 {
      display: none;
    }

    .mobile-950 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 900px) {
    .desktop-900 {
      display: none;
    }

    .mobile-900 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 850px) {
    .desktop-850 {
      display: none;
    }

    .mobile-850 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 800px) {
    .desktop-800 {
      display: none;
    }

    .mobile-800 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 750px) {
    .desktop-750 {
      display: none;
    }

    .mobile-750 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 700px) {
    .desktop-700 {
      display: none;
    }

    .mobile-700 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 650px) {
    .desktop-650 {
      display: none;
    }

    .mobile-650 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 600px) {
    .desktop-600 {
      display: none;
    }

    .mobile-600 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .map-container {
    height: 100%;
    width: 100%;
  }

  .mainHomeContainer {
    color: rgb(89, 46, 44);
    background-color: #FAF7E8;
    overflow: hidden;
    width: 100%;

    .slider {
      display: flex;
      width: 100%;
      transition: opacity 0.5s ease-in-out;

      .slide {
        width: 100%;
        height: 600px;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;

        @media (max-width: 1200px) {
          height: 550px;
        }

        @media (max-width: 1100px) {
          height: 500px;
        }

        @media (max-width: 1000px) {
          height: 450px;
        }

        @media (max-width: 900px) {
          height: 400px;
        }

        @media (max-width: 750px) {
          height: 350px;
        }

        &.inactive {
          opacity: 0;
          width: 0;
          height: 0;
          overflow: hidden;
        }

        h1 {
          margin-top: 40px;
          text-align: center;
          width: 600px;
          max-width: calc(100% - 40px);
          margin-left: auto;
          margin-right: auto;
        }

        .slideMainContainer {
          display: flex;
          flex-direction: row;
          width: 1300px;
          max-width: calc(100% - 40px);
          margin-left: auto;
          margin-right: auto;
          align-items: center;

          &.reverse {
            flex-direction: row-reverse;
          }

          .slideMediaContainer {
            flex: 11;

            img {
              width: 100%;
            }

            .slideImageContainer {
              padding-top: 100%;
              position: relative;

              .slideImageContainerInner {
                position: absolute;
                top: 50%;
                width: 100%;
                transform: translateY(-50%);
              }
            }
          }

          .sliderDescription {
            flex: 10;
            padding-left: 50px;
            padding-right: 50px;

            h1 {
              text-align: left;
              width: 100%;
              margin-left: 0;
              margin-right: 0;
            }

            .slideMediaContainerMobile {
              display: none;
            }
          }

          .subLink {
            margin-top: 10px;

            a {
              text-transform: uppercase;
              font-weight: bold;
              color: $textColor;
            }
          }
        }

        @media (max-width: 900px) {

          .slideMainContainer h2 {
            display: none;
          }
        }

        @media (max-width: 600px) {
          padding-top: 40px;
          height: 750px;

          .slideMainContainer {
            flex-direction: column;

            &.reverse {
              flex-direction: column;
            }

            .slideMediaContainer {
              display: none;
            }

            .sliderDescription {
              padding-left: 0;
              padding-right: 0;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;

              h1 {
                text-align: center;
                width: 100%;
              }

              .slideMediaContainerMobile {
                display: block;
                width: 100%;

                img {
                  width: 100%;
                }

                .slideImageContainer {
                  padding-top: 100%;
                  position: relative;

                  .slideImageContainerInner {
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    transform: translateY(-50%);
                  }
                }
              }
            }
          }
        }

        @media (max-width: 600px) {
          height: 820px;
        }

        @media (max-width: 525px) {
          height: 775px;
        }

        @media (max-width: 500px) {
          height: 750px;
        }

        @media (max-width: 450px) {
          height: 700px;
        }

        @media (max-width: 350px) {
          height: 675px;
        }
      }
    }

    .sliderButtonsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 100px;

      .sliderButtons {

        label {
          display: inline-block;
          margin: 0 5px;

          input[type='radio'] {
            display: none;
          }

          span {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: rgb(89, 46, 44);
            cursor: pointer;

            &::before {
              content: '';
              display: block;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: none;
              transform: translate(2px, 2px);
              transition: transform 0.2s ease-in-out;
            }
          }

          input[type='radio']:checked+span::before {
            background-color: #FAF7E8;
          }
        }
      }
    }

    .mainInfoContainer {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      width: 1200px;
      max-width: 100%;
      margin: auto;

      .productContainer {
        text-align: center;
        width: 100%;
        margin-top: 100px;
        padding: 0 20px;

        h2 {
          font-size: 30px;
        }

        .productText {
          margin-left: auto;
          margin-right: auto;
          width: 500px;
          max-width: 100%;
        }

        .productsContainer {
          text-align: center;
          margin: 0 -20px;

          .productImgContainer {
            display: inline-block;
            width: 25%;
            margin-top: 30px;
            padding: 0 20px;

            @media (max-width: 982px) {
              width: 33%;
            }

            @media (max-width: 750px) {
              width: 50%;
            }

            img {
              height: 100%;
              width: 100%;
            }

            p {
              width: 100%;
              font-weight: 500;
              padding: 0;
              color: rgb(230, 75, 56);
            }
          }
        }
      }

      .trainingSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;

        h1 {
          text-align: center;
          font-size: 45px;
          margin-left: 20px;
          margin-right: 20px;
        }

        .trainingDetails {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          margin-right: 20px;

          .trainingDetailsContentContainer {
            flex: 1;

            h2 {
              font-size: 30px;
            }

            .trainingSectionParagraph {
              margin-bottom: 30px;
            }

            button {
              background-color: $primaryColorLight;
              color: $textColor;
            }

            .downloadAppTitle {
              text-transform: uppercase;
              font-weight: bold;
              margin-top: 30px;
            }

            .appStoreButtonsContainer {

              a {
                display: inline-block;
                width: 135px;
                margin-right: 10px;

                img {
                  width: 100%;
                }
              }
            }
          }

          .trainingDetailsImageContainer {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            img {
              width: 100%;
              max-width: 400px;
            }
          }

          @media (max-width: 620px) {
            flex-direction: column;

            .trainingDetailsImageContainer {
              margin-top: 40px;
            }
          }
        }

        .videoOuter {
          width: 1050px;
          max-width: 100%;
          padding: 50px 23px 0;

          .videoContainer {
            width: 100%;
            padding-top: 56.25%;
            position: relative;
            margin-top: 50px;

            .videoBackground {
              position: absolute;
              background-color: $primaryColor;
              bottom: -3px;
              right: -3px;
              width: 100%;
              height: 100%;
              border-radius: 8px;
              overflow: hidden;
            }

            .videoInner {
              position: absolute;
              top: -3px;
              left: -3px;
              width: 100%;
              height: 100%;
              background-color: #000;
              border-radius: 8px;
              overflow: hidden;

              .trainingVideoContainer {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }

      .shopAllSection {
        margin-top: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        margin-right: 20px;

        .shopAllContentContainer {
          flex: 1;

          h2 {
            font-size: 30px;
          }

          .shopAllContentParagraph {
            margin-bottom: 30px;
          }
        }

        .shopAllImageContainer {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          img {
            width: 100%;
            max-width: 400px;
          }
        }

        @media (max-width: 620px) {
          flex-direction: column;

          .shopAllImageContainer {
            margin-top: 40px;
          }
        }
      }

      .reviewSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;

        h2 {
          font-size: 30px;
          margin-bottom: 10px;
          margin-left: 20px;
          margin-right: 20px;
          text-align: center;
        }

        .reviewSubtitle {
          width: 400px;
          text-align: center;
          margin-bottom: 50px;
          max-width: calc(100% - 40px);
        }

        .reviewContainer {
          display: flex;
          text-align: center;

          .review {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .reviewerImage {
              width: calc(100% - 40px);
            }

            .ratingStars {
              width: 100px;
              margin: 20px auto 0px;
            }

            p {
              padding: 0;
              text-align: center;
              font-size: 12px;
              width: calc(100% - 40px);
              max-width: 250px;
              line-height: 1;

              &:last-of-type {
                margin-top: 2px;
              }
            }
          }

          @media(max-width: 900px) {

            div:nth-child(3) {
              display: none;
            }
          }

          @media (max-width: 600px) {

            div:nth-child(2) {
              display: none;
            }

            div:nth-child(3) {
              display: none;
            }
          }
        }
      }

      .instagramSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 100px;
        align-items: center;
        justify-content: center;

        h2 {
          font-size: 30px;
          margin-bottom: 10px;
          margin-left: 20px;
          margin-right: 20px;
          text-align: center;
        }

        p {
          width: 400px;
          text-align: center;
          margin-bottom: 25px;
          max-width: calc(100% - 40px);
        }
      }
    }

    .banner {
      font-family: BuenosAires, sans-serif;
      margin-top: 80px;
      width: 125%;
      margin-left: -10%;

      .bannerScroll {
        display: flex;
        position: relative;
        height: 30px;
        margin: auto;
        overflow: hidden;
        z-index: 1;
      }

      .bannerScrollTitle {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        transition: all 1s ease;
      }

      .bannerScrollTitle>div {
        display: flex;
        animation: scrollText 60s infinite linear;
      }

      .bannerScrollTitle .marqueeTextContainer {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        transition: all 2s ease;
      }

      .bannerScrollTitle>div:hover {
        animation-play-state: paused;
      }

      @keyframes scrollText {
        from {
          transform: translateX(0%);
        }

        to {
          transform: translateX(-50%);
        }
      }

      .marqueeText {
        margin-right: 150px;
      }
    }
  }
}