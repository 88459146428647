.CartCustomContent {
  position: relative;

  .content-container {
    padding: 20px;

    .content-inner {
      display: flex;
      flex-direction: row;

      .preview {
        margin-top: 20px;
      }

      .left {
        flex: 3;
      }

      .right {
        flex: 2;
      }
    }

    @media (max-width: 900px) {
      .content-inner {
        flex-direction: column;
      }
    }
  }
}
