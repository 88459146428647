.Footer {
  position: relative;

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
  }

  .content-container {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
    padding-top: 20px;

    h3 {
      margin: 0 20px 20px;
    }

    .footer-container {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}
