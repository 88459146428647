@import '../../../../../../styles/colors';

.GuaranteeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: $textColor;
  font-family: BuenosAires, sans-serif;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 56px 16px 0px 16px;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
  }

  .content {
    margin: 0;
    font-size: 18px;
  }

  .image {
    max-height: 400px;
    width: 100%;
    object-fit: contain;
  }

  .button {
    background: transparent;
    color: $textColor;
    text-transform: uppercase;
    font-weight: 400;
    font-family: BuenosAires, sans-serif;
    text-wrap: nowrap;
    border: 2px solid $textColor;
    border-radius: 25px;
  }

  @media (min-width: 768px) {
    .contentWrapper {
      max-width: 70%;
    }
  }
}
