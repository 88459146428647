.RewardsSettings {
  position: relative;

  .content {
    background-color: #fff;
    padding: 20px 15px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    table {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-top: none;
    }

    .row {
      display: flex;
      flex-direction: row;
    }

    .row > * {
      flex: 1;
      margin: 10px 5px;
    }

    .sub-title-container {
      margin: 20px -16px;
    }

    .section-container {
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 15px;
      border-radius: 4px;
      margin: 20px 0;
      width: 100%;

      .section-label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 0.75rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
        background-color: #fff;
        margin-top: -25px;
        padding: 5px;
        width: fit-content;
      }

      .pricing-row {
        margin-top: 20px;
      }

      .right-container {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 15px;
      }

      .description-section {
        position: relative;

        .description-actions {
          display: flex;
          position: absolute;
          right: 45px;
          z-index: 1;
        }
      }
    }
  }
}
