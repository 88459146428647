.EditFeaturedProduct {
  
  .container {
    padding: 20px;
    width: 100%;

    .section-container {
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 15px;
      border-radius: 4px;
      margin: 20px 0;
      width: 100%;
  
      .section-label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
        background-color: #fff;
        margin-top: -25px;
        padding: 5px;
        width: fit-content;
        margin-bottom: 10px;
      }

      .rules-button-row {
        display: flex;
        flex-direction: row-reverse;
      }

      .condition-text {
        font-weight: bold;
      }

      .rule-group {
        border: 1px solid #ddd;
        margin: 20px 0;
        padding: 10px;

        .form-row {
          display: flex;
          flex-direction: row;
          margin: 20px 0;
          padding: 20px 0;
        }
      }
    }
  }
}
