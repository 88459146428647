.FlaggedPosts {
  position: relative;
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    border: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
