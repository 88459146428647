.AmazonOrderHistory {
  position: relative;

  .card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -5px;
    align-items: stretch;

    .card-container {
      margin: 5px;
      flex: 1;

      .MuiCard-root {
        height: 100%;
      }

      .card-spacing {
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 800px) {
    .card-row {
      flex-direction: column;
    }
  }

  .sub-title-container {
    margin: 40px -21px 20px;
  }
}
