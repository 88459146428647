.CompletionPopupsAnalytics {
  text-align: center;
  margin-bottom: 50px;

  .CompletionPopupsAnalyticsInner {
    margin-top: 50px;
    display: inline-block;
    text-align: left;
    width: calc(100% - 40px);

    .drag-card {
      width: 100%;

      .toolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .dates-row {
        display: flex;
        position: relative;
        z-index: 1000;
        margin-bottom: 30;
        align-content: flex-end;
  
        .dates-row-item-container {
          display: flex;
          flex-direction: column-reverse;
          margin-left: 10px;
  
          button {
            margin-bottom: 3px;
          }
  
          .earning-type-select {
            width: 150px;
            margin-bottom: 3px;
  
            .MuiOutlinedInput-inputMarginDense {
              padding-bottom: 9px;
              padding-top: 9px;
            }
          }
        }
      }

      .table-container {
        margin-top: 20px;
      }
    }
  }
}
