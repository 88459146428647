.accordion-main-container {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.menu-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.accordion-container {
  background-color: #f5f5f5 !important;
  box-shadow: none !important;
  border: none !important;
  display: flex;
  flex-direction: column;
  .text-field {
    & .MuiOutlinedInput-input {
      background-color: white;
    }
  }
}

.external-accordion-container {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiAccordion-root:before {
  content: none !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
