.EditBlogPost {
  position: relative;

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
  }

  .content-container {
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .editor-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.12);

      &.sticky {
        padding-top: 85px;

        .toolbar-container {
          position: fixed;
          top: 64px;
          background-color: #fff;
          z-index: 2;
        }
      }

      .editor-container {
        min-height: 300px;
        padding-left: 10px;
        padding-right: 10px;

        pre {
          background-color: #FFB3B9;
        }
      }
    }

    .editor-popup {

      .rdw-embedded-modal-btn-section,
      .rdw-link-modal-buttonsection,
      .rdw-image-modal-btn-section {
        display: flex;
        width: 100%;

        button {
          flex: 1;

          &:first-of-type {
            background-color: #ff3312;

            &:disabled {
              background-color: rgba(0, 0, 0, 0.12);
            }
          }

          &:last-of-type {
            background-color: #f50057;
          }
        }
      }
    }

    .form-row {
      margin-top: 10px;

      .categories-container {
        border: 1px solid rgba(0, 0, 0, 0.23);
        padding: 15px;
        border-radius: 4px;
        margin: 20px 0;
        width: 100%;
    
        .add-category-button {
          color: blue;
          cursor: pointer;
        }
    
        .MuiInputLabel-shrink {
          background-color: #fff;
          padding: 0 5px;
        }
      }
    }
  }
}
