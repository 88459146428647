.ProdSectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  padding: 56px 16px;
  gap: 24px;
  font-family: BuenosAires, sans-serif;

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
  }

  .content {
    margin: 0;
    font-size: 18px;
  }

  .itemsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 768px) {
    .contentContainer {
      max-width: 70%;
    }

    .itemsContainer {
      flex-direction: row;
      align-items: flex-start;
    }
  }
}
