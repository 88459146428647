.HeroContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: BuenosAires, sans-serif;

  .heroImage {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heroContent {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    justify-content: center;
    padding: 56px;
    min-height: 400px;
  }

  .HeroTitle {
    text-transform: uppercase;
    font-size: 40px;
    margin: 0;
  }

  .HeroContent {
    font-size: 20px;
    margin: 0;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 16px;
    width: 100%;

    a {
      text-decoration: underline;
      text-wrap: nowrap;
      text-transform: uppercase;
    }
    a:hover {
      opacity: 0.6;
    }
  }

  .button {
    background-color: transparent;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: BuenosAires, sans-serif;
    text-wrap: nowrap;
    width: 50%;
  }

  .link {
    padding: 12px 24px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 600px) {
  .HeroContainer {
    .buttonsContainer {
      flex-direction: column;
      .button {
        min-width: 80%;
      }
    }
  }
}

@media (max-width: 962px) {
  .HeroContainer {
    .heroContent {
      padding: 32px;
    }
    .HeroTitle {
      font-size: 28px;
    }
    .HeroContent {
      font-size: 16px;
    }

    grid-template-columns: 1fr;

    .heroImage {
      max-height: 400px;
    }
  }
}
