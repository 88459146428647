.QuizModule {
  position: relative;

  .question-container {
    margin: 7px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .question-inputs-container {
      flex: 1;

      .answers-container {
        margin-top: 10px;

        .answer-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          .answer-letter {
            font-size: 16px;
            margin-right: 10px;
          }

          .question-button-container {
            margin-left: 7px;
            position: relative;

            .correct-text {
              position: absolute;
              top: -26px;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .question-buttons-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 3px;
      margin-left: 5px;

      .question-button-container {
        margin-left: 7px;
      }
    }
  }
}
