.GiftCards {
  position: relative;

  .content-container {
    padding: 20px;
    width: 100%;

    .input-row {
      display: flex;
      flex-direction: row;
    }
  }

  .download-button-row {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
