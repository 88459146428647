.CourseModule {
  position: relative;

  .condensed-text-field {
    margin-top: 15px;
  }

  .section-container {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 15px;
    border-radius: 4px;
    margin: 15px 0 0;
    width: 100%;

    .section-label {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 0.75rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      background-color: #fff;
      margin-top: -25px;
      padding: 5px;
      width: fit-content;
    }

    .right-container {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 15px;
    }

    .link-row {
      display: flex;
      flex-direction: row;
      margin: 10px 0;
      justify-content: space-between;

      .link-item {
        margin: 0 5px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .editor-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 15px 0 0;

    .editor-container {
      min-height: 300px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .editor-popup {

    .rdw-embedded-modal-btn-section,
    .rdw-link-modal-buttonsection,
    .rdw-image-modal-btn-section {
      display: flex;
      width: 100%;

      button {
        flex: 1;

        &:first-of-type {
          background-color: #ff3312;

          &:disabled {
            background-color: rgba(0, 0, 0, 0.12);
          }
        }

        &:last-of-type {
          background-color: #f50057;
        }
      }
    }
  }
}
