.ServiceCategory {
  position: relative;

  .top-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .last-modified-container {
    color: rgba(0, 0, 0, 0.5);
    font-size: 13px;
    margin-bottom: 5px;
  }

  .content-container {
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .services-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      align-items: center;

      .services-header-title {
        font-size: 20px;
      }
    }
  }
}
