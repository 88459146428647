.EditHeaderBanner {
  position: relative;

  .container {
    padding: 20px;
    padding-bottom: 80px;
    width: 100%;

    .banner-settings-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .color-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        display: inline-flex;
        box-sizing: border-box;
        align-items: center;
        margin-top: 23px;
        margin-bottom: 4px;
        cursor: pointer;
        padding: 11.8px 14px;
        margin-right: 15px;
        min-width: 210px;

        &.last {
          margin-right: 0;
        }

        p {
          color: rgba(0, 0, 0, 0.87);
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.1876em;
          letter-spacing: 0.00938em;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          margin: 0;
        }

        .color-preview {
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          height: 20px;
          width: 20px;
        }
      }

      @media (max-width: 880px) {
        flex-direction: column;
        align-items: flex-start;

        .color-container {
          width: 100%;
        }
      }
    }
  }
}
