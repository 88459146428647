.PageBuilder {

  div,
  textarea {
    box-sizing: border-box;
  }

  .items-closed {
    width: 100%;
  }

  .items-open {
    width: calc(100% - 300px);
  }

  .page-builder-drag-items-container {
    height: calc(100vh - 45px);
  }
}

@font-face {
	font-family: BuenosAires;
	src: url(https://pupford-fonts.b-cdn.net/BuenosAiresVariableUpright.ttf);
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-webfont.woff) format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-oblique-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-oblique-webfont.woff) format("woff");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-webfont.woff) format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-oblique-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-oblique-webfont.woff) format("woff");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-webfont.woff) format("woff");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: Visby;
	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-oblique-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-oblique-webfont.woff) format("woff");
	font-weight: 800;
	font-style: italic
}
