.EditOneClickUpsell {
  position: relative;

  .MuiFormHelperText-contained {
    margin-left: 0;
    margin-top: 0;
  }

  .MuiInputAdornment-positionStart {
    margin-right: 0;
  }

  .buttons-row {
    display: flex;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
  }

  .last-modified-container {
    color: rgba(0, 0, 0, 0.5);
    font-size: 13px;
    margin-bottom: 5px;
  }

  .delete-button {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    text-align: center;
    background-color: #ccc;
    z-index: 1;

    &:hover {
      background-color: #bbb;
    }

    p {
      line-height: 19px;
      margin: 0;
      height: 0;

    }
  }

  .content-container {
    padding: 10px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);

    .row {
      display: flex;
      margin-bottom: 10px;
    }

    .tabs-container {
      margin: 20px -10px;

      .container {
        padding: 10px;
        width: 100%;

        .section-container {
          border: 1px solid rgba(0, 0, 0, 0.23);
          padding: 15px;
          border-radius: 4px;
          margin: 20px 0;
          width: 100%;
    
          .rules-button-row {
            display: flex;
            flex-direction: row-reverse;
          }
    
          .condition-text {
            font-weight: bold;
          }
    
          .rule-group {
            border: 1px solid #ddd;
            margin: 20px 0;
            padding: 10px;
    
            .form-row {
              display: flex;
              flex-direction: row;
              margin: 20px 0;
              padding: 20px 0;
            }
          }
        }

        .pages-container {
          display: flex;
          margin: 10px 0;

          .left-container {
            flex: 1;
            padding: 10px;

            .upsell-page-container {
              position: relative;
            }

            .page-flow-container {
              width: 100%;
              background-color: rgba(0, 0, 0, 0.05);
              border-radius: 4px;
              padding: 0 10px;
              text-align: center;
              margin-bottom: 10px;
              border: 1px solid rgba(0, 0, 0, 0.1);

              &.upsell-page {
                cursor: pointer;
                border: 1px solid rgba(0, 0, 0, 0.3);

                &.selected {
                  border-color: #ff3312;
                }

                &:hover {
                  opacity: 0.85;
                }
              }
            }
          }

          .right-container {
            flex: 2;
            margin: 10px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 4px;

            .reject-button-class-name {
              margin: 30px 0;

              span {
                background-color: rgba(0, 0, 0, 0.08);
                border-radius: 4px;
                padding: 2px 8px;
              }
            }

            table {
              border-collapse: collapse;
              width: 100%;

              td {
                padding: 5px;
                border-top: 1px solid #ddd;

                p span {
                  background-color: rgba(0, 0, 0, 0.08);
                  border-radius: 4px;
                  padding: 2px 8px;
                }

                img {
                  align-self: center;
                }

                input[type=number] {
                  padding-right: 0;
                }
              }
            }
          }
        }

        @media (max-width: 1000px) {
          .pages-container {
            flex-direction: column;
          }
        }
      }
    }
  }
}
