.FormReports {
  position: relative;

  .content-container {
    padding-bottom: 0;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .dates-row {
      display: flex;
      position: relative;
      z-index: 1000;
      margin-bottom: 30;
      align-content: flex-end;
      padding: 10px;

      .dates-row-item-container {
        display: flex;
        flex-direction: column-reverse;
        margin-left: 10px;

        button {
          margin-bottom: 3px;
        }

        .earning-type-select {
          width: 150px;
          margin-bottom: 3px;

          .MuiOutlinedInput-inputMarginDense {
            padding-bottom: 9px;
            padding-top: 9px;
          }
        }
      }
    }

    .data-container {
      padding: 10px;
    }
  }
}
