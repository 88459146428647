.EditFile {
  position: relative;

  .edit-image-body {
    display: flex;
    flex-direction: row;
    
    .image-container {
      flex: 2;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .image-info-container {
      flex: 1;
      padding: 20px;
      color: rgba(0, 0, 0, 0.7);
      font-size: 12px;
      background-color: #F3F3F3;
      min-height: calc(100vh - 64px);
      border-left: 1px solid #bbb;

      p {
        margin: 5px 0;
      }

      .divider-spacing {
        margin-top: 15px;
      }

      .delete-button-container {
        margin-top: 10px;
      }
    }
  }
}
