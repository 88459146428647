.FormConditionEditor {
  position: relative;

  .form-condition-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  .form-condition-description {
    margin: 0;
    margin-bottom: 10px;
  }

  .field-condition-container {
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.01);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 10px;
    position: relative;

    .delete-button {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      text-align: center;
      background-color: #ccc;
      z-index: 1;

      &:hover {
        background-color: #bbb;
      }

      p {
        line-height: 19px;
        margin: 0;
        height: 0;
  
      }
    }

    .field-condition-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      .field-condition-label {
        font-weight: bold;
        text-transform: uppercase;
        width: 75px;
      }

      .state-option-item {
        text-transform: capitalize;
      }
    }
  }

  .apply-to-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .trash-container {
      margin-left: 10px;
    }
  }
}
