.FileManager {

  .breadcrumb-container {
    display: flex;
    margin-bottom: 10px;

    a {
      color: blue;
    }

    .breadcrumb-item-container {
      display: flex;

      .breadcrumb-item {
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }

  .file-container {
    width: 20%;
    display: inline-block;
    padding: 20px;
    color: #000;
    text-decoration: none;

    .file-container-inner {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      cursor: pointer;

      .folder-image {
        height: 150px;
        position: relative;

        img {
          width: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .file-image {
        height: 150px;
        position: relative;
        overflow: hidden;

        img {
          width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px 10px;
      }
    }

    .file-container-inner:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 1300px) {
    .file-container {
      width: 25%;
    }
  }

  @media (max-width: 1100px) {
    .file-container {
      width: 33.33%;
    }
  }

  @media (max-width: 850px) {
    .file-container {
      width: 50%;
    }
  }

  @media (max-width: 450px) {
    .file-container {
      width: 100%;
    }
  }
}

.file-manager-modal-container {
  padding: 20px;
  width: 600px;

  .path-field-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .path-prefix {
      margin-right: 10px;
      font-size: 11px;
      color: rgba(0, 0, 0, 0.8);
      white-space: nowrap;
    }
  }
}
