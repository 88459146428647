.InsuranceClaims {
  position: relative;

  .reason-cell {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pagination-container {
    padding: 20px;
    display: flex;
    flex-direction: row-reverse;

    .page-text {
      margin: 0 10px;
    }
  }
}
