.EditCouponCode {

  .container {
    padding: 20px;
    padding-bottom: 80px;
    width: 100%;

    .date-picker-container {
      position: relative;
      z-index: 100;
    }

    .section-container {
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 15px;
      border-radius: 4px;
      margin-top: 20px;
      width: 100%;

      .section-label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 0.75rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
        background-color: #fff;
        margin-top: -25px;
        padding: 5px;
        width: fit-content;
      }
    }
  }
}
