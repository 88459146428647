.FeaturedContentEdit {
  margin-bottom: 50px;
  margin-top: 70px;
  padding: 20px;
  width: 100%;

  .loadingContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

    .input-field {
      width: calc(50% - 5px);
    }
  }
}
