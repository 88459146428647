.NewSubscriptions {
  position: relative;

  .content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;

    .text-spacing {
      margin-top: 10px;
    }

    .date-picker-row {
      display: flex;
      position: relative;
      z-index: 1000;
      margin-bottom: 30px;
      align-items: flex-end;

      .date-picker-submit-button {
        margin-bottom: 1px;
        margin-left: 7px;
      }
    }
  }

  .card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -5px;
    align-items: stretch;

    .card-container {
      margin: 5px;
      flex: 1;

      .MuiCard-root {
        height: 100%;
      }

      .card-spacing {
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 800px) {
    .card-row {
      flex-direction: column;
    }
  }

  .sub-title-container {
    margin: 40px -21px 20px;
  }
}
