.accordion-main-container {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.menu-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.color-preview {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.accordion-trigger-active {
  margin-top: 8px;
}

.accordion-trigger {
  margin-top: 64px;
}

.accordion-container {
  background-color: #f5f5f5 !important;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.12);

  .text-field {
    & .MuiOutlinedInput-input {
      background-color: white;
    }
  }
}

.MuiAccordion-root:before {
  content: none !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
