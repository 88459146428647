.ProductItemContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  max-width: 250px;
  width: 100%;
  max-width: 90%;

  .ProductItemImage {
    height: 80px;
    width: 80px;
    object-fit: cover;
  }

  .ProductItemTitle {
    font-size: 16px;
    margin: 0;
    text-wrap: wrap;
  }

  .ProductItemLink {
    font-size: 14px;
    text-decoration: underline;
    font-weight: 600;
  }

  .ProductContentContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
}

.ProductButton {
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  font-family: BuenosAires, sans-serif;
  text-wrap: nowrap;
  text-align: center;
  font-size: 14px;
  width: 200px;
  border-radius: 25px;
}

@media (min-width: 550px) {
  .ProductItemContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 250px;

    .ProductItemImage {
      height: 160px;
      width: 160px;
      object-fit: cover;
    }
  }
}
