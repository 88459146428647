.WebsiteContentHeader {
  position: relative;

  .section-container {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 15px;
    border-radius: 4px;
    margin: 20px 0;
    width: 100%;

    .section-label {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      background-color: #fff;
      margin-top: -25px;
      padding: 5px;
      width: fit-content;
    }
  }

  .custom-label {
    font-weight: 600;
    font-size: 13px;
    margin: 15px 0 7px;
    display: block;
  }

  .logo-outer-container {

    .custom-label {
      margin-bottom: 12px;
    }

    .logo-container {
      position: relative;
      display: inline-block;
  
      .logo-delete-button {
        position: absolute;
        right: -8px;
        top: -8px;
        visibility: hidden;
        cursor: pointer;
      }
  
      img {
        max-width: 150px;
      }
    }
  
    .logo-container:hover {
  
      .logo-delete-button {
        visibility: inherit;
      }
    }
  }

  .editing-nav-width-container {
    margin-top: 15px;
  }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .accordion-header-left {
      display: flex;
      align-items: center;
    }
  }

  .accordion-content {

    .delete-button-container {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
    }

    .child-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .child-inputs {
        flex: 1;
      }
    }
  }
}
